import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    device: {},
    oo_token: localStorage.getItem("oo_token") || "",
    user: {},
  },
  mutations: {
    addDevice(state, payload) {
      state.device = payload;
    },
    addToken(state, payload) {
      state.oo_token = payload;
    },
    addUser(state, payload) {
      state.user = payload;
    },
    logout(state, payload) {
      console.log(payload);
      state.oo_token = "";
      localStorage.setItem("oo_token", "");
    },
  },
  getters: {
    getDevice: (state) => {
      return state.device;
    },
    getToken: (state) => {
      return state.oo_token;
    },
    isLoggedIn: (state) => !!state.oo_token,
    getUser: (state) => {
      return state.user;
    },
  },
  actions: {},
  modules: {},
});
