<template>
  <div id="app">
    <div :class="'app-vp app-' + device.platform">
      <notifications group="notification" position="top center" class="notification-wrap alert-notification" />

      <div v-if="isLoggedIn" class="body-loggedin">
        <!-- Desktop Navigation -->
        <header class="app-header cf">
          <div class="container">
            <div class="header-left">
              <span @click="showNavigation = true" class="resp nav-toggle material-icons">menu</span>
              <ul class="nav nav-main cf">
                <li>
                  <router-link to="/campaigns">
                    <img src="./assets/icons/adone.svg" />
                    <span>{{ $t("nav.ads") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/devices">
                    <img src="./assets/icons/adtwo.svg" />
                    <span>{{ $t("nav.displays") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/advertisments">
                    <img src="./assets/icons/media.svg" />
                    <span>{{ $t("nav.media") }}</span>
                  </router-link>
                </li>
              </ul>
              <label for="menu-main" id="menu-main-overlay"></label>
            </div>
            <router-link to="/dashboard" class="logo">
              <div>
                <img :src="require('./assets/themes/' + _self.$theme + '/logo.png')" />
              </div>
            </router-link>
            <div v-if="device.platform == 'web'" class="header-right">
              <input type="checkbox" id="menu-user" />
              <label for="menu-user" class="resp user-toggle material-icons">person</label>
              <ul class="nav nav-main cf">
                <li>
                  <router-link to="/balance">
                    <img src="./assets/icons/guthaben.svg" />
                    <span>{{ $t("global.balance") }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/user" class="user">
                    <img v-if="user.avatar" :src="user.avatar" @error="userImageNotFound" />
                    <span v-if="user.first_name">{{ user.first_name }} {{ user.last_name }}</span>
                    <span v-if="!user.first_name">Profil</span>
                  </router-link>
                </li>
                <li v-if="user.admin">
                  <router-link to="/admin/dashboard">
                    <img src="./assets/icons/admin.svg" />
                  </router-link>
                </li>
                <li>
                  <a @click="logout" href="#">
                    <img src="./assets/icons/logout.svg" />
                  </a>
                </li>
              </ul>
              <label for="menu-user" id="menu-user-overlay"></label>
            </div>
          </div>
        </header>

        <!-- Mobile Navigation -->
        <md-drawer :md-active.sync="showNavigation" md-swipeable>
          <header class="header-drawer">
            <div class="user-account cf">
              <router-link to="/user" class="user">
                <img :src="user.avatar" @error="userImageNotFound" />
                <span>{{ user.first_name }} {{ user.last_name }}</span>
              </router-link>
              <router-link to="/balance" class="balance">
                <img src="./assets/icons/guthaben.svg" />
                <span>{{ user.balance_formatted }}</span>
              </router-link>
            </div>
          </header>

          <div class="drawer-divider">
            <h3>{{ $t("nav.mobile.adHeadline") }}</h3>
            <ul class="nav nav-drawer">
              <li>
                <router-link to="/campaigns" @click="showNavigation = false">
                  <img src="./assets/icons/adone.svg" />
                  <span>{{ $t("nav.ads") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/devices">
                  <img src="./assets/icons/adtwo.svg" />
                  <span>{{ $t("nav.displays") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/advertisments">
                  <img src="./assets/icons/media.svg" />
                  <span>{{ $t("nav.media") }}</span>
                </router-link>
              </li>
            </ul>
          </div>

          <div class="drawer-divider">
            <h3>{{ $t("nav.mobile.profilHeadline") }}</h3>
            <ul class="nav nav-drawer">
              <li>
                <router-link to="/balance">
                  <img src="./assets/icons/guthaben.svg" />
                  <span>{{ $t("nav.balance") }}</span>
                </router-link>
              </li>
              <li>
                <router-link to="/user" class="user">
                  <img src="./assets/icons/user.svg" />
                  <span>{{ $t("nav.profil") }}</span>
                </router-link>
              </li>
              <li>
                <a @click="logout" href="#">
                  <img src="./assets/icons/logout.svg" />
                  <span>{{ $t("nav.logout") }}</span>
                </a>
              </li>
            </ul>
          </div>
        </md-drawer>

        <router-view />
      </div>

      <div v-else>
        <!-- Register Sidebar -->
        <div class="body-auth">
          <div v-if="device.platform == 'web' && this.$theme == 'oohlemon'" v-bind:class="{ sidebar: showSidebar }">
            <div class="sidebar-content" v-if="showSidebar">
              <div class="text-wrapper">
                <h2 v-html="$t('login.bannerHeadline')"></h2>
                <hr />
                <h3>{{ $t("login.bannerSubline") }}</h3>
              </div>
              <div class="image-grid">
                <img src="./assets/images/examples/example1.jpg" />
                <img src="./assets/images/examples/example4.jpg" />
                <img src="./assets/images/examples/example2.jpg" />
                <img src="./assets/images/examples/example3.jpg" />
              </div>
            </div>
            <div class="app-auth-wrap">
              <p class="logo">
                <img src="./assets/themes/oohlemon/logo.png" alt="Logo" />
              </p>
              <div class="app-auth">
                <router-view />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="app-auth-wrap app-auth-wrap-mobile">
              <div class="logo">
                <div>
                  <img :src="require('./assets/themes/' + _self.$theme + '/logo.png')" />
                </div>
              </div>
              <div class="app-auth">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Plugins } from "@capacitor/core";
const { Device } = Plugins;

export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  data() {
    return {
      showNavigation: false,
      showSidebar: false,
      device: {},
      user: {},
      current_route: "",
    };
  },
  methods: {
    get_version() {
      axios.get(process.env.VUE_APP_BASE_APP, {}).then((response) => {
        this.meta = response.data;
      });
    },
    get_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          // redirect if not map and not any auth page
          if (this.$router.currentRoute.name && this.$router.currentRoute.name !== "myMap" && this.$router.currentRoute.name !== "redirector" && this.$router.currentRoute.name !== "coupon_form" && !this.$route.path.includes("auth")) {
            if (error.response.status === 401 || error.response.status === 500) {
              if (this.$store.getters.isLoggedIn == true) {
                this.$store.commit("logout", "");
              }
              this.$router.push("/auth/sign_in");
            }
          }
        });
    },
    async get_device_info() {
      const info = await Device.getInfo();
      this.device = info;
      this.$store.commit("addDevice", info);
    },
    logout() {
      this.$store.commit("logout", "");
      var _this = this;
      setTimeout(function () {
        _this.$router.push("/");
      }, 250);
    },
    userImageNotFound(img) {
      img.target.src = require("./assets/icons/face.svg");
    },
  },
  mounted() {
    this.get_version();
    this.get_user();
    this.get_device_info();
    this.current_route = this.$router.currentRoute.name;
  },
  watch: {
    $route() {
      this.current_route = this.$router.currentRoute.name;
      if (this.$router.currentRoute.name === "auth_sign_up") {
        this.showSidebar = true;
      } else {
        this.showSidebar = false;
      }

      this.showNavigation = false;
      this.get_user();
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/main.scss";

.sidebar {
  display: flex;
  flex-direction: row;

  .sidebar-content {
    background: var(--ci-color);
    color: var(--ci-contrast-color);

    width: 35vw;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 2.5rem;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;

      strong {
        font-weight: 600;
      }
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    hr {
      margin: 20px 0;
      border-top: 2px solid #444444;
      max-width: 30px;
    }
  }
}

.app-auth-wrap {
  margin-top: 0 !important;
}

.image-grid {
  display: flex;
  flex-flow: wrap;
  padding: 0 calc(5vw - 5px);

  img {
    border-radius: 15%;
    width: 50%;
    padding: 10px;
    height: 25vh !important;
    object-fit: cover;
    
    filter: brightness(1) saturate(1);

    &:hover {
      filter: brightness(0.9) saturate(0.5);
    }
  }
}

.text-wrapper {
  padding: 0 5vw;
}

@media (max-width: 1024px) {
  .sidebar-content {
    display: none !important;
  }
}

.sidebar .app-auth-wrap .auth {
  margin-top: 9vh;
}
</style>
