import 'core-js/stable';
import 'regenerator-runtime/runtime'

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import vuetify from "vuetify";
import VueResource from "vue-resource";
import { MdDrawer, MdTooltip } from "vue-material/dist/components";
import "vue-material/dist/vue-material.min.css";
import VueI18n from "vue-i18n";
import messages from "./messages";
import VueHtml2Canvas from "vue-html2canvas";
import VModal from "vue-js-modal";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import Notifications from "vue-notification";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

Vue.config.productionTip = false;
Vue.use(Chartkick.use(Chart));
Vue.use(MdDrawer);
Vue.use(MdTooltip);
Vue.use(VModal, { dynamic: true });
Vue.use(Notifications);
Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(VueHtml2Canvas);

const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages, // set locale messages
});

if (!process.env.VUE_APP_WHITELABEL) {
  // OOHLEMON THEMING
  document.documentElement.classList.add("theme-oohlemon");
  document.documentElement.setAttribute("theme", "oohlemon");
  Vue.prototype.$theme = "oohlemon";
  Vue.prototype.$themeColor = "#ffed00";
  Vue.prototype.$themeContrastColor = "#454545";
} else if (process.env.VUE_APP_WHITELABEL) {
  // WHITELABEL THEMING
  let themeName = process.env.VUE_APP_WHITELABEL;  
  document.documentElement.classList.add("theme-" + themeName);
  document.documentElement.setAttribute("theme", themeName);
  Vue.prototype.$theme = themeName;
  Vue.prototype.$themeColor = "#dddddd";
  Vue.prototype.$themeContrastColor = "#454545";
  i18n.locale = themeName;
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

defineCustomElements(window);
