const messages = {
  de: {
    global: {
      balance: "Guthaben",
      campaigns: "Kampagnen",
      devices: "Bildschirme",
      playlists: "Playlists",
      schedule: "Zeitplan",
      schedules: "Zeitpläne",
      info: "Info",
      infos: "Infos",
      company: "Unternehmen",
      firstname: "Vorname",
      lastname: "Nachname",
      street: "Straße",
      housenumber: "Hausnummer",
      postalcode: "Postleitzahl",
      city: "Stadt",
      vatid: "VAT ID",
      privacy: "Datenschutz",
      website: "Webseite",
      name: "Name",
      delete: "aus Playlist entfernen",
      createNow: "Jetzt erstellen ›",
      saveNow: "Jetzt speichern ›",
      save: "Speichern",
      saveButton: "Speichern ›",
      weekday: "Wochentag",
      monday: "Montag",
      thuesday: "Dienstag",
      wednesday: "Mittwoch",
      thursday: "Donnerstag",
      friday: "Freitag",
      saturday: "Samstag",
      sunday: "Sonntag",
      from: "Von",
      to: "bis",
      adExternal: "Fremdwerbung",
      adInternal: "Hausintern",
      close: "Schließen",
      slot: "Slot",
      duration: "Dauer",
      seconds: "Sekunden",
      add: "Hinzufügen",
      format: "Format",
      chooseAll: "Alle auswählen",
      chooseNone: "Alle abwählen",
      updateButton: "Aktualisieren ›",
      status: "Status",
      created: "Erstellt",
      linked: "Verbunden",
      located: "Lokalisiert",
      running: "Läuft",
      hidden: "Ausgeblendet",
      deviceID: "Device ID",
      default: "Standard",
      showed: "Einblendungen",
      show: "Einblendung",
      contacts: "Impressionen",
      cost: "Ausgaben",
      addPaymentStart: "Bitte füge zuerst eine",
      addPaymentMethod: "Bezahlmethode",
      addPaymentEnd: "hinzu",
      details: "Details",
      adType: "Anzeigentyp",
      image: "Bild",
      video: "Video",
      budget: "Budget",
      period: "Zeitraum",
      location: "Standort",
      click: "Hier klicken",
      settings: "Einstellungen",
      amount: "Betrag",
      methode: "Methode",
      added: "Hinzugefügt am",
      date: "Datum",
      success: "Erfolgreich",
      declined: "abgelehnt",
      invoices: "Rechnungen",
      createdAt: "erstellt am",
      and: "und",
      ratio: "Seitenverhältnis",
      mandatory: "Pflichtfeld",
      stats: "Statistiken",
      toStep2: "Zu Schritt 2 ›",
      nextPage: "Nächste Seite",
      prevPage: "Vorherige Seite",
      change: "Anpassen",
      generator: "AdGenerator",
      generatorInfo: "Mit dem Generator können sie einfach per drag & drop eine neue Anzeige generieren",
    },
    link: {
      website: "https://oohlemon.com",
      privacy: "https://oohlemon.com/datenschutz/",
      agb: "https://oohlemon.com/agb/",
    },
    asset: {
      mapIcon: "/map.png",
      mapIconOffer: "/map_offer.png",
      mapIconPremium: "/map_premium.png",
      logo: "./assets/themes/oohlemon/logo.png",
    },
    status: {
      draft: "Entwurf",
      creating: "Erstellung",
      created: "Erstellt",
      pending: "Freigabe",
      running: "Läuft",
      stopped: "Gestoppt",
      paused: "Pausiert",
      blocked: "Blockiert",
      deleted: "Gelöscht",
    },
    notification: {
      saveSuccess: "Erfolgreich gespeichert.",
      changeSuccess: "Erfolgreich geändert.",
      deletedSuccess: "Erfolgreich gelöscht.",
      errorValidation: "Bitte überprüfe deine Eingaben.",
    },
    nav: {
      ads: "Werbung schalten",
      displays: "Bildschirme verwalten",
      media: "Medien",
      balance: "Guthaben",
      profil: "Profil",
      logout: "Logout",
      mobile: {
        adHeadline: "Werbung verwalten",
        profilHeadline: "Account verwalten",
      },
    },
    login: {
      bannerHeadline: "Deine Plattform für <br /><strong>digitale </strong> <br />Außenwerbung",
      bannerSubline: "Sichere dir zum Start unseren 10 € Gutschein. Schalte eigenständige Kampagnen oder verbinde dein Display mit dem Netzwerk und lass andere bei dir werben.",
    },
    dashboard: {
      title: "Dashboard",
      ads: "Werbung schalten",
      adsDescription: "Deine Werbung in unserem Display-Netzwerk schalten.",
      adsCreate: "Kampagne erstellen ›",
      display: "Bildschirme verwalten",
      displaySubline: "Werbung auf deinem Displays anzeigen und verwalten.",
      displayButton: "Bildschirme verwalten ›",
      start: "Getting Started",
      startDescription: "So verwaltest du in <strong>5 einfachen Schritten</strong> dein Display.",
      startPay: "Bezahlmethode hinterlegen",
      startDisplay: "Display hinzufügen",
      startPlaylist: "Playlist erstellen und mit Medien befüllen",
      startTime: "Zeitplan befüllen mit deiner erstellten Playlist",
      startDevices: "Zeitplan deinem Display zuweisen",
      startCta: "Los geht's! Dein Display spielt nun Werbung ab.",
    },
    profil: {
      title: "Dein Profil",
      description:
        "Die Profilangaben zu deiner Person und deinem Unternehmen. Der persönliche Partnerlink für Weiterempfehlungen und zusätzliche Provisionen findest du weiter unten.",
      dataTitle: "Stammdaten bearbeiten",
      button: "Profil aktualisieren ›",
      editImage: "Profilbild bearbeiten",
      profilImage: "Profilbild",
      uploadError: "Bitte eine Datei auswählen.",
      uploadButton: "Jetzt hochladen ›",
      industryTitle: "Branche definieren",
      industryDescription:
        "Um Werbung schalten zu können, müssen wir wissen in welcher Branche dein Unternehmen tätig ist. Zusätzlich wird auf deinem Bildschirm keine Werbung von Unternehmen aus der gleichen Branche angezeigt.",
      chooseIndustry: "Branche auswählen",
      chooseSubIndustry: "Unterbranche auswählen",
      saveIndustryButton: "Branche speichern ›",
      industryError: "Bitte die Branche aktualisieren!",
      partnerTitle: "Partnerprogramm",
      partnerDescription: `
        <p>Werbe neue Werbetreibende oder Display-Partner und verdiene mit!<br />Teile deinen Partner Link und mit jedem User, den du wirbst, verdienst du bei jeder Bezahlung des Users aktiv mit.</p>
        <h3>Lukrative Verdienstmöglichkeiten</h3><br/>
        <p>Mehr Informationen hierzu findest du auf unserer Webseite.</p>
        <p><strong>Display-Partner:</strong><br />Für jede Werbeausstrahlung: 10 %</p>
        <p><strong>Werbetreibende:</strong><br />10 % für jede erstellte Kampagne</p><br/>
        <h3>Dein Partner Link</h3>`,
      partnerLink: "Link kopieren",
      partnerLinkShare: "Deinen Link teilen",
      partnerLinkCopy: "Link kopiert!",
      partnerLinkCopyNow: "Jetzt kopieren",
      privacyDescription: "Das Thema Datenschutz ist uns sehr wichtig, weitere Informationen hierzu findest du auf unserer",
    },
    schedule: {
      description:
        "Dein Display muss wissen wann welche Playlist abgespielt werden soll, definiere hier in deinem Zeitplan wann welche Playlist abgespielt werden soll. Nun kannst du unter dem Menüpunkt Bildschirme deinen Zeitplan dem jeweiligen Bildschirm zuweisen.",
      add: "Zeitplan hinzufügen",
      empty: "Keine Zeitpläne vorhanden.",
      deletePrompt: "Zeitplan wirklich löschen?",
      deleted: "Zeitplan wurde erfolgreich gelöscht.",
      defaultIntern: "Standard Hausintern",
      defaultExtern: "Standard Fremdwerbung",
      newTitle: "Neuen Zeitplan hinzufügen",
      created: "Zeitplan erfolgreich gespeichert.",
      manage: "Zeitplan verwalten",
      addSlot: "Slot hinzufügen",
      createSlot: "Slot erstellen",
      timeframe: "Zeitraum auswählen",
      week: "Die ganze Woche",
      type: "Werbetyp",
      choose: "Playlist auswählen",
      deleteSlot: "Möchtest du diesen Slot löschen?",
      slotError: "Eine Playlist muss mindestens über einen Slot verfügen.",
      slotDelete: "Slot löschen",
    },
    playlists: {
      title: "Playlist verwalten",
      showPlaylists: "Anzeigen in Playlist",
      empty: "Es sind aktuell keine Anzeigen in dieser Playlist.",
      remove: "Von Playlist entfernen ›",
      moveDown: "Nach unten ⬇",
      moveUp: "Nach oben ⬆",
      availableAds: "Verfügbare Anzeige",
      emptyAds: "Es sind aktuell keine Anzeigen verfügbar.",
      notAvailableAd: "Diese Anzeige steht aktuell noch nicht zur Verfügung.",
      addToPlaylist: "Zur Playlist hinzufügen ›",
      addNewPlaylist: "Neue Playlist hinzufügen",
      saved: "Playlist erfolgreich gespeichert.",
      description: "Befülle hier deine Playlist oder erstelle eine Neue. Ganz einfach deine hochgeladenen Werbemittel hinzufügen und anordnen.",
      descriptionInfo: "Eine Playlist muss immer einem Zeitplan zugewiesen werden, damit du weißt wann diese Playlist abgespielt werden soll.",
      addPlaylist: "Playlist hinzufügen",
      noPlaylist: "Keine Playlists vorhanden.",
      delete: "Playlist wirklich löschen?",
      deleted: "Playlist wurde erfolgreich gelöscht.",
      set_as_fallback: "Als Fallback gesetzt",
    },
    devices: {
      title: "Display hinzufügen",
      location: "Standort auswählen",
      locationTitle: "Wo befindet sich das Display?",
      locationDescription: "Bitte setze auf der Karte einen Marker wo sich das Display befindet.",
      adressSearch: "Adresse eingeben",
      toStep: "Zu Schritt",
      verification: "Verifizierungsbild fotografieren",
      verificationTitle: "Bild von deinem Display erstellen",
      verificationDescription:
        "Auf dem Display wird aktuell ein Verifizierungsbild angezeigt, bitte fotografiere dieses mit der Umgebung und lade es hier hoch.",
      chooseImage: "Bild auswählen",
      takeFoto: "oder Foto machen",
      verificationButton: "Verifizierung abschließen ›",
      stats: "Statistiken",
      statsHelp: "Hier siehst du die Statistiken über die Einblendungen und die Impressionen, die mit dem Display gesammelt werden.",
      ads: "Aktuelle Ad-Pipeline",
      adsHelp: "Hier sehst du die aktuellen Werbemittel die am Display ausgestrahlt werden.",
      adsEmpty: "Aktuell sind noch keine Anzeigen in der Pipeline.",
      adsReport: "Anzeige melden",
      adsStatus: "Anzeige wurde",
      adRequest: "Angefragt",
      adServed: "Ausgeliefert",
      and: "und von",
      people: "Personen",
      watched: "gesehen",
      time: "Uhr",
      adsOpen: "Ad-Pipeline aufklappen",
      industires: "Branchen definieren",
      industriesHelp:
        "Wähle hier aus, welche Branchen bei dir werben dürfen und welche nicht. Beachte, dass umso weniger du zulässt, umso weniger können wir vermittlen. Das wirkt sich auf deinen Verdienst aus.",
      display: "Display Informationen",
      displayHelp: "Hier verwaltest du die Informationen zu deinem Display.",
      newImage: "Neues Bild auswählen",
      scheduleUsed: "Hinterlegter Zeitplan",
      scheduleEmpty: "Kein Zeitplan hinterlegt",
      saveName: "Name speichern",
      placeholder: `Öffnungszeiten:
Zielgruppe:
Kontakte:`,
      saveInfos: "Infos speichern",
      changeSchedule: "Zeitplan ändern",
      saveSchedule: "Zeitplan speichern",
      saveOrientation: "Ausrichtung speichern",
      displaySettings: "Display Einstellungen",
      displaySettingsHelp: "Definiere hier die Ausrichtung deines Displays anhand des Winkels und setzt den Zeitplan den du für dieses Diplay verwenden willst.",
      orientation: "Orientierung",
      degree: "Grad Winkel",
      adsReportSure: "Möchtest du diese Anzeige wirklich melden?",
      reason: "Grund",
      connectDisplay: "Display verbinden",
      authTitle: "Auth Code eingeben",
      authDescription: "Gebe den Auth Code ein, der sich auf dem Display befindet.",
      createName: "Namen vergeben",
      createNameInfo: "Du kannst dem Display einen Namen geben, damit du dieses später wiederfindest.",
      step2: "Zu Schritt 2 ›",
      yours: "Deine Bildschirme",
      yoursInfo: "Hier verwaltest du deine Bildschirme, Playlists und die dazugehörigen Zeitpläne.",
      addDisplay: "Bildschirm hinzufügen",
      noDisplays: "Keine Displays vorhanden.",
      addDisplayButton: "Jetzt Display hinzufügen ›",
      deleteDisplay: "Display wirklich löschen?",
      deletedDiplay: "Display wurde erfolgreich gelöscht.",
    },
    campaigns: {
      title: "Deine Kampagnen",
      info: "Hier kannst du deine Werbekampagne in 3 einfachen Schritten schalten. Suche oder gestalte deine Werbemittel, wähle einen Zeitraum und die Bildschirme aus, danach nur noch dein Budget festlegen und los geht's.",
      add: "Kampagne hinzufügen",
      empty: "Keine Kampagnen vorhanden.",
      addButton: "Jetzt Kampagne hinzufügen ›",
      create: "Werbung schalten",
      createInfo: "Schalte Deine Kampagne in wenigen Sekunden!",
      step1: "Kampagne konfigurieren",
      name: "Name eingeben",
      nameInfo: "Damit du diese Kampagne später wiederfindest, kannst du einen Namen vergeben.",
      namePlaceholder: "Name der Kampagne...",
      industry: "Branche auswählen",
      industryInfo: "Bitte wähle die Branche deiner Kampagne aus, damit die Kampagne besser zugeordnet werden kann.",
      industryMain: "Hauptbranche auswählen",
      industrySub: "Unterbranche auswählen",
      settings: "Experteneinstellungen",
      settingsInfo: "Mit diesen optionalen Einstellungen kannst du deine Kampagne jetzt noch besser targetieren.",
      period: "Zeitraum festlegen",
      periodInfo: "Soll deine Kampagne nur für einen bestimmten Zeitraum laufen",
      startDate: "Startdatum",
      endDate: "Enddatum",
      periodTime: "Zeitfenster festlegen",
      periodTimeInfo: "Soll deine Kampagne nur zu bestimmten Uhrzeiten laufen?",
      periodStart: "Uhrzeit Start",
      periodEnd: "Uhrzeit Ende",
      openSettings: "Experteneinstellungen öffnen ›",
      toStep2: "Zur Medien+Display Auswahl ›",
      step2: "Inhalt und Zielgruppe definieren",
      mediaTitle: "Anzeige auswählen oder hochladen",
      mediaInfo: "Deine Anzeigen werden unter dem Menüpunkt Medien verwaltet. Bitte wähle hier aus, welche Datei du nutzen möchten.",
      mediaSearch: "Nach Medien suchen",
      mediaPlaceholder: "Suchbegriff eingeben...",
      mediaUpload: "Anzeige hochladen ›",
      mediaCreate: "Anzeige erstellen ›",
      mediaEmpty: "Aktuell sind noch keine Medien vorhanden. Um diese Kampagne zu vervollständigen müsst du erst eine Datei hochladen.",
      uploadNow: "Jetzt hochladen ›",
      locationTitle: "Geografisch eingrenzen",
      locationInfo: "Definiere das geografische Gebiet in dem deine Kampagne ausgeliefert werden soll.",
      frequency: "Frequenz definieren",
      frequencyInfo: "Manche Geräte werden häufiger frequentiert als andere. Wähle auf welchen Geräten deine Werbung laufen soll.",
      frequencyHigh: "Hohe Frequenz",
      frequencyHighInfo: "Werbung wird im ø von > 2 Personen gesehen",
      frequencyLow: "Normale Frequenz",
      frequencyLowInfo: "Werbung wird im ø von ca. 1 Person gesehen.",
      industries: "Branchen auswählen",
      industriesInfo: "Jedes Display ist einer Branche zugewiesen, du kannst hierdurch gezielt bestimmte Branchen ansteuern.",
      toStep3: "Budget anpassen ›",
      step3: "Budget festlegen",
      budgetTitle: "Tagesbudget festlegen",
      budgetInfo: "Bitte lege ein Tagesbudget für diese Kampagne fest.",
      budgetLabel: "EUR pro Tag",
      budgetAlert1: "Das Tagesbudget von",
      budgetAlert2: "entspricht in deinem definierten Radius",
      deliveries: "Auslieferungen",
      perMonth: "pro Monat",
      createButton: "Zu deiner Zusammenfassung ›",
      pending: "Kampagne wird überprüft",
      pendingInfo1:
        "Diese Kampagne ist aktuell noch nicht aktiv. Wir prüfen derzeit den Inhalt und die Einstellungen der Kampagne und schalten diese schnellstmöglichst frei. Dieser Prozess erfolgt manuell und kann bis zu 48 Stunden in Anspruch nehmen. Du wirst informiert sobald deine Kampange aktiv ist.",
      pendingInfo2: "Dein Konto wird mit ",
      pendingInfo3: "täglich belastet und automatisch in 14 Tagen von unserem Bezahlsystem abgebucht.",
      pendingStop: "Kampagnenprüfung stoppen ›",
      campaignStart: "Kampagnenstart",
      campaignEnd: "Kampagnende",
      campaignCreated: "Erstellt am",
      campaignUpdated: "Aktualisiert am",
      campaignStop: "Kampagne anhalten",
      campaignDelete: "Kampagne löschen",
      campaignStartButton: "Kampagne starten",
      campaignInactive: "Kampagne noch nicht aktiv",
      campaignInactiveInfo:
        "Diese Kampagne ist aktuell noch nicht aktiv. Wenn du mit den Einstellungen fertig bist kannst du die Kampagne zur Prüfung einreichen.",
      campaignCheck: "Kampagne einreichen ›",
      stats: "Statistiken",
      statsInfo: "Die Statistiken werden im Stundentakt aktualisiert.",
      statsEmpty: "Noch keine Statistiken vorhaben.",
      displayName: "Display Name",
      delivered: "Ausgeliefert am",
      budget: "Budget pro Tag in €",
      location: "Geografische Eingrenzung",
      old: "Diese Kampagne ist veraltet und muss neu erstellt werden.",
      funnel: "Funnel",
      impressions: "Impressionen",
      impression: "Impression",
      contacts: "Kontakte",
      qrScans: "QR Scans",
      per: "pro",
      summaryInfo: "Im Durschnitt wird dein Werbemittel 10 mal in der Stunde pro Display abgespielt.",
      yourcampaign: "Deine Kampagneninfos: ",
      summaryDevices: "Displayanzahl: ",
      summaryDuration: "Laufzeit: ",
      summaryBudget: "Daher empfehlen wir dir ein Kampagnenbudget von: ",
      summaryduration: "Standard Spotlänge: 15 Sekunden",

    },
    balance: {
      info: "Hier siehst du dein Guthaben und kannst deine Bankverbindung hinterlegen, um Abbuchungen und Auszahlungen zu tätigen. Du hast einen Gutscheincode? Diesen kannst du hier einlösen.",
      getPayed: "Auszahlung anfordern ›",
      methods: "Bezahlmethoden",
      sepa: "SEPA Lastschrift",
      creditcard: "Kreditkarte",
      card: "Karte",
      empty: "Keine Bezahlmethode vorhanden. Bitte hinzufügen.",
      emptyInvoice: "Keine Rechnungen vorhanden.",
      coupons: "Coupons",
      couponsInfo: "Sie haben einen Coupon? Jetzt einlösen.",
      couponCode: "Coupon Code",
      redeem: "Jetzt einlösen ›",
      transactions: "Transaktionen",
      pending: "Wird abgebucht",
      transactionsEmpty: "Keine Transaktionen vorhanden.",
      paymentSuccess: "Bezahlung erfolgreich.",
      invoiceMail: "Rechnung wurde erfolgreich per E-Mail versendet.",
      addPayment: "Bezahlmethode hinzufügen",
      sepaData: "Kontodaten eingeben",
      sepaSave: "Kontodaten speichern ›",
      sepaDisclaimer:
        "Durch die Eingabe deiner IBAN und durch das Bestätigen der Bezahlung, authorisierst Du (A) oohlemon GmbH und Stripe, unseren Bezahldienstleister, die SEPA-Lastschrift bei (B) deiner Bank abzubuchen. Sollte eine Buchung fehlerhaft durchgeführt worden sein, habst Du das Recht die Buchung innerhalb von 8 Wochen bei deiner Bank und der dort geltenden Bedingungen rückgängig zu machen.",
      cardAdd: "Kreditkarte hinzufügen",
      cardData: "Kreditkartendaten",
      cardSave: "Kreditkarte speichern ›",
      paymentAdded: "Bezahlmethode hinzugefügt",
      payoutTitle: "Guthaben anfordern",
      payoutTitle2: "Jetzt Auszahlung anfordern",
      payoutAmount: "Betrag eingeben",
      payoutButton: "Jetzt Auszahlung anfordern ›",
      payoutMessage: "Auszahlung wurde angefordert und wird jetzt geprüft.",
      addTitle: "Neue Bezahlmethode hinzufügen",
      adress: "Rechnungsadresse auswählen",
      select: "Bezahlmethode auswählen",
      addInfo: "Kontodaten hinterlegen",
      redirect: "Du wirst im nächsten Schritt zu unserem Bezahldienstleister weitergeleitet.",
      emptyPayment: "Du benötigst mindestens eine Rechnungsadresse um Bezahlmethode hinzuzufügen.",
      addAdress: "Rechnungsadresse hinzufügen",
      newAdress: "Neue Rechungsadresse",
      editAdress: "Rechungsadresse bearbeiten",
    },
    auth: {
      registration: "Registrierung",
      password: "Passwort",
      acceptStart: "Ich habe die",
      privacy: "Datenschutzbestimmungen",
      agb: "AGB",
      acceptEnd: "gelesen und akzeptiere diese.",
      registrationButton: "Jetzt Registrieren ›",
      account: "Du hast bereits einen Account?",
      loginButton: "Einloggen ›",
      registrationInfo: "Die Registrierung war erfolgreich. Bitte bestätige deine E-Mail Adresse bevor du dich einloggen kannst.",
      passwordLost: "Passwort vergessen?",
      noAccount: "Noch keinen Account?",
      createAccount: "Jetzt Account anlegen ›",
      loginSuccess: "Erfolgreich eingeloggt.",
      passwordReset: "Passwort zurücksetzen",
      resetButton: "Passwort wiederherstellen ›",
      noReset: "Passwort doch nicht vergessen?",
      resetNote: "Du erhälst eine E-Mail mit weiteren Details.",
      confirmNote: "Account wurde bestätigt, du kannst dich jetzt einloggen.",
      tokenAlert: "Bitte Token angeben.",
      newPassword: "Neues Passwort",
      repeatPassword: "Neues Passwort wiederholen",
      resetConfirm: "Das Passwort wurde erfolgreich gesetzt. Du kannst dich jetzt einloggen.",
    },
    ads: {
      title: "Deine Medien",
      description: "Hier kannst du deine vorhandenen Medien hochladen.",
      add: "Medium hinzufügen",
      empty: "Keine Medien vorhanden.",
      button: "Jetzt Medium hinzufügen ›",
      newTitle: "Neues Medium",
      upload: "Datei hochladen",
      available: "Werbung vorhanden?",
      uploadNow: "Jetzt hochladen!",
      uploadInfo: "Die Datei per Drag and Drop hier ablegen oder hier klicken um eine Datei auszuwählen.",
      vertical: "Anzeige im Querformat erstellen.",
      horizontal: "Anzeige im Hochformat erstellen.",
      editor: "Hier klicken um den Kampagnen-Editor zu öffnen.",
      canva: "Erstelle mit Canva, einem online Grafiktool mit einer breiten Auswahl an Vorlagen, deine eigene Anzeige schnell und kostenlos.",
      allowed: "Erlaubte Dateiformate",
      settings: "Empfohlene Einstellungen",
      editing: "Wir bearbeiten die Dateien beim Upload automatisch, Tonspuren werden entfernt und die Qualität wird auf einen Standard angepasst.",
      mediaInfo: "Medium Infos",
      type: "Ad Typ",
      progress: "Upload Fortschritt",
      fileError: "Bitte eine Datei hochladen.",
      editTitle: "Medium bearbeiten",
    },
    map: {
      search: "Adresse suchen",
      location: "Ausgewählter Standort",
      radius: "Radius (in m)",
    },
    generator: {
      image: "Werbebild hochladen",
      logo: "Logo hochladen",
      button: "Werbung generieren ›",
      yours: "Deine generierte Werbung",
      edit: "Bild bearbeiten",
      upload: "Werbung hochladen ›",
      title: "Überschrift",
      description: "Beschreibung",
      coininfo: "Scan Beschreibung",
      couponinfo: "Kontakdaten",
      qrcode: "Der passende QR-Code wird später in diesem Bereich automatisch generiert.",
    },
  },
  whitelabel: {
    link: {
      website: "https://oohlemon.com",
      privacy: "https://oohlemon.com/datenschutz/",
      agb: "https://oohlemon.com/agb/",
    },
    asset: {
      mapIcon: "/whitelabel/map.png",
      mapIconOffer: "/whitelabel/map.png",
      mapIconPremium: "/whitelabel/map.png",
      logo: "./assets/themes/whitelabel/logo.png",
    },
  },
};

export default messages;
