import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "home", component: () => import("../views/home.vue") },
  { path: "/mymap", name: "myMap", component: () => import("../views/myMap.vue") },

  { path: "/r/:id", name: "redirector", component: () => import("../views/redirect/show.vue") },
  { path: "/coupons/form/:id", name: "coupon_form", component: () => import("../views/forms/show.vue") },

  {
    path: "/auth/sign_up",
    name: "auth_sign_up",
    component: () => import("../views/auth/sign_up.vue"),
  },
  {
    path: "/auth/sign_in",
    name: "auth_sign_in",
    component: () => import("../views/auth/sign_in.vue"),
  },
  {
    path: "/auth/confirm",
    name: "auth_confirm",
    component: () => import("../views/auth/confirm.vue"),
  },
  {
    path: "/auth/password",
    name: "auth_password",
    component: () => import("../views/auth/password.vue"),
  },
  {
    path: "/auth/password/edit",
    name: "auth_password_edit",
    component: () => import("../views/auth/password/edit.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard.vue"),
  },

  {
    path: "/user",
    name: "user",
    component: () => import("../views/user/profile.vue"),
  },

  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("../views/campaigns/index.vue"),
  },
  {
    path: "/campaigns/new",
    name: "new_campaign",
    component: () => import("../views/campaigns/new.vue"),
  },
  {
    path: "/campaigns/:uuid",
    name: "campaign",
    component: () => import("../views/campaigns/show.vue"),
  },

  {
    path: "/devices",
    name: "devices",
    component: () => import("../views/devices/index.vue"),
  },
  {
    path: "/devices/new",
    name: "new_device",
    component: () => import("../views/devices/new.vue"),
  },
  {
    path: "/devices/:uuid",
    name: "device",
    component: () => import("../views/devices/show.vue"),
  },

  {
    path: "/playlists",
    name: "playlists",
    component: () => import("../views/playlists/index.vue"),
  },
  {
    path: "/playlists/new",
    name: "new_playlist",
    component: () => import("../views/playlists/new.vue"),
  },
  {
    path: "/playlists/:uuid",
    name: "playlist",
    component: () => import("../views/playlists/show.vue"),
  },

  {
    path: "/advertisments/new",
    name: "new_advertisment",
    component: () => import("../views/advertisments/new.vue"),
    alias: "/playlists/:uuid/advertisments/new",
  },

  {
    path: "/schedules",
    name: "schedules",
    component: () => import("../views/schedules/index.vue"),
  },
  {
    path: "/schedules/new",
    name: "new_schedule",
    component: () => import("../views/schedules/new.vue"),
  },
  {
    path: "/schedules/:uuid",
    name: "schedule",
    component: () => import("../views/schedules/show.vue"),
  },

  {
    path: "/advertisments",
    name: "advertisments",
    component: () => import("../views/advertisments/index.vue"),
  },
  {
    path: "/advertisments/:uuid",
    name: "advertisment",
    component: () => import("../views/advertisments/show.vue"),
  },
  {
    path: "/generator",
    name: "generator",
    component: () => import("../views/generator.vue"),
  },
  {
    path: "/balance",
    name: "balance",
    component: () => import("../views/balance/index.vue"),
  },
  {
    path: "/balance/setup_intents/new",
    name: "balance_setup_intent",
    component: () => import("../views/balance/setup_intents/new.vue"),
  },

  {
    path: "/balance/payment_methods/new",
    name: "balance_payments",
    component: () => import("../views/balance/payment_methods/new.vue"),
  },
  {
    path: "/balance/customers/new",
    name: "new_balance_customer",
    component: () => import("../views/balance/customers/new.vue"),
  },

  {
    path: "/balance/payouts/new",
    name: "new_balance_payout",
    component: () => import("../views/balance/payouts/new.vue"),
  },

  {
    path: "/admin/dashboard",
    name: "admin_dashboard",
    component: () => import("../views/admin/dashboard/index.vue"),
  },
  {
    path: "/admin/users",
    name: "admin_users",
    component: () => import("../views/admin/users/index.vue"),
  },
  {
    path: "/admin/users/:uuid",
    name: "admin_user",
    component: () => import("../views/admin/users/show.vue"),
  },

  {
    path: "/admin/campaigns",
    name: "admin_campaigns",
    component: () => import("../views/admin/campaigns/index.vue"),
  },
  {
    path: "/admin/campaigns/:uuid",
    name: "admin_campaign",
    component: () => import("../views/admin/campaigns/show.vue"),
  },

  {
    path: "/admin/networks",
    name: "admin_networks",
    component: () => import("../views/admin/networks/index.vue"),
  },
  {
    path: "/admin/networks/new",
    name: "new_admin_network",
    component: () => import("../views/admin/networks/new.vue"),
  },
  {
    path: "/admin/networks/:uuid",
    name: "admin_network",
    component: () => import("../views/admin/networks/show.vue"),
  },

  {
    path: "/admin/displays",
    name: "admin_displays",
    component: () => import("../views/admin/displays/index.vue"),
  },
  {
    path: "/admin/displays/:uuid",
    name: "admin_display",
    component: () => import("../views/admin/displays/show.vue"),
  },
  {
    path: "/admin/settings",
    name: "admin_settings",
    component: () => import("../views/admin/settings/index.vue"),
  },
  {
    path: "/admin/articles",
    name: "admin_articles",
    component: () => import("../views/admin/articles/index.vue"),
  },
  {
    path: "/admin/articles/new",
    name: "new_admin_article",
    component: () => import("../views/admin/articles/new.vue"),
  },
  {
    path: "/admin/articles/:uuid",
    name: "admin_article",
    component: () => import("../views/admin/articles/show.vue"),
  },

  {
    path: "/admin/coupons",
    name: "admin_coupons",
    component: () => import("../views/admin/coupons/index.vue"),
  },
  {
    path: "/admin/coupons/new",
    name: "new_admin_coupon",
    component: () => import("../views/admin/coupons/new.vue"),
  },

  {
    path: "/admin/payouts",
    name: "admin_payouts",
    component: () => import("../views/admin/payouts/index.vue"),
  },

  {
    path: "/admin/payouts/:uuid",
    name: "admin_payouts_detail",
    component: () => import("../views/admin/payouts/show.vue"),
  },

  {
    path: "/admin/viewers",
    name: "admin_viewers",
    component: () => import("../views/admin/viewers/index.vue"),
  },
  {
    path: "/admin/viewers/:uuid",
    name: "admin_viewer",
    component: () => import("../views/admin/viewers/show.vue"),
  },

  {
    path: "/admin/schedules/:uuid",
    name: "admin_schedule",
    component: () => import("../views/admin/schedules/show.vue"),
  },

  {
    path: "/admin/playlists/:uuid",
    name: "admin_playlist",
    component: () => import("../views/admin/playlists/show.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
